var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
      _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
      _vm.detailHide,
    ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"tbody"},_vm._l((_vm.responseData.creditNote),function(tr,i){return _c('vs-tr',{key:i},[_c('vs-td',[_c('div',{staticClass:"flex gap-3"},[_c('vx-tooltip',{staticStyle:{"width":"fit-content"},attrs:{"text":"Detail"}},[_c('vs-button',{attrs:{"color":"green","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){return _vm.showDetail(tr)}}})],1)],1)]),_c('vs-td',[_vm._v(_vm._s(tr.Code))]),_c('vs-td',[_vm._v(_vm._s(tr.Type))]),_c('vs-td',[_vm._v(" Name : "+_vm._s(tr.CustomerName)+" "),_c('br'),_vm._v(" Code : "+_vm._s(tr.CustomerCode)+" ")]),_c('vs-td',[_vm._v(_vm._s(_vm.dateFormat(tr.PostingDate)))]),_c('vs-td',[_c('p',[_vm._v(" Reversal Note : "+_vm._s(tr.ReversalNote ? tr.ReversalNote : "-")+" ")]),_c('p',[_vm._v(" "+_vm._s(tr.Type === "Manual" ? "Reference : " : "Note : ")+" "+_vm._s(tr.Note ? tr.Note : "-")+" ")])])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('detail',{attrs:{"selected":_vm.selectedData},on:{"close":_vm.closeDetail}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }